// Home page-specific rules

@import 'helpers/variables';
@import 'helpers/mediaqueries';
@import 'helpers/mixins';

.bgd-split--static {
  background-image: url(../images/hero-bg.jpg);
}

.help-list {
  margin: 0px;
  margin-top: 28px;
  padding: 0px;

  @include respond-to($large-min) {
    margin-top: 44px;
  }

  dt {
    color: #2E1B11;
    font-family: $font-alt;
    font-weight: 700;
  }

  dd {
    margin: 0px 0px 20px;
    padding: 0px 0px 15px;
    border-bottom: 1px solid #e1e1e1;
    overflow: scroll;

    @include respond-to($large-min) {
      overflow: hidden;
    }
  }

  ol {
    margin-left: 5px;
    padding-left: 15px;

    ul {
      padding-left: 15px;
    }
  }

  + h3 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 22px;
    color: $color-brand;
  }

  a {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
}

// Locations
.locations-image {

  img {
    max-width: 100%;
  }
}

@mixin city-header-underline {
  margin: 30px 0px 10px;
  height: 30px;
  border-bottom: 1px solid $color-light-grey;
}

h3.locations__city {
  color: $color-grey;
  font-family: $font-default;
  font-size: 14px;
  padding-bottom: 5px;
  overflow: hidden;
  @include city-header-underline;
}

.locations__header {
  font-family: $font-default;
  font-size: 20px;
  margin-top: 50px;

  + h3.locations__city {
    margin-top: 20px;
  }
}

ul.locations {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 0px 20px;
  padding: 0px;
  list-style: none;
}

.locations__item {
  color: $color-grey;
  padding: 0px 0px 10px;
  width: 100%;
  font-size: 14px;

  @include respond-to($medium-min) {
    padding: 0px 20px 10px 0px;
    width: 33%;
  }
}

.locations__title {
  color: $color-dark-grey;
  margin-bottom: 5px;
  font-family: $font-default;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3;
}

.locations__item--span {
  @include respond-to($medium-min) {
    padding-right: 0px;
  }
}

.locations__spacer {
  @include respond-to($medium-min) {
    @include city-header-underline;
  }
}

.panel--legal {
  h3 {
    color: #2e1b11;
  }
}

.rewards-table {
  margin: 20px 0px;

  th {
    text-align: left;
  }

  td {
    padding: 10px 20px 0px 0px;
    vertical-align: top;
  }
}
