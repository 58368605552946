/**
*
* Variables
*
**/

/*==========  Paths  ==========*/
$img-path: "../images/";
// wp font path
$font-path: "../fonts/";

/*==========  Font Sizes  ==========*/
$font-small: 13px;
$font-base: 16px;

/*==========  Colors  ==========*/
$color-brand: #505054;
$color-primary: #532d6d;
// $color-primary-dark: #271931;
$color-primary-dark: #111820;
$color-accent: #59386e;
$color-primary-contrast: #fff;
$color-accent-contrast: #fff;

$color-background: #fbf8f4;

$color-alert-neutral: #777;
$color-alert-neutral-bgd: #fffcf5;
$color-alert-success: #3c763d;
$color-alert-success-bgd: #dff0d8;
$color-alert-warn: #8a6d3b;
$color-alert-warn-bgd: #fcf8e3;
$color-alert-danger: #a94442;
$color-alert-danger-bgd: #f2dede;

$color-black: #222;
$color-white: #fff;

$color-purple: #532d6d;
$color-grey: #8a8a8d;
$color-light-grey: #e1e1e1;
$color-dark-grey: #505054;

/* ==========  Fonts  ========== */
$font-default: 'Lato', sans-serif !default;
$font-alt: 'Montserrat', sans-serif !default;
$font-default-cond: 'Montserrat', sans-serif;

/* ==========  Typography  ========== */
$color-text: #505050;
$color-text-link: $color-primary;
$color-text-link-hover: $color-primary-dark;

/* ==========  Header/Navigation  ========== */
$header-height: 83px;
$header-height-large: 83px;

/* ==========  Header/Navigation  ========== */
/* ==========  Standard Buttons  ========== */

// Default button colors.
