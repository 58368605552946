// Media Queries
$screen: "only screen";

$landscape: "orientation: landscape";
$portrait: "orientation: portrait";

$xsmall-max: 349px;

$small-min: 350px;
$small-max: 479px;

$medium-min: 480px;
$medium-max: 839px;

$large-min: 840px;
$large-max: 1199px;

$xlarge-min: 1200px;
$xlarge-max: 1599px;

$xxlarge-min: 1600px;

@mixin respond-to($min-width, $max-width: false, $orientation: false) {
  @media screen and (min-width: $min-width) {
    @if $max-width {
      @media (max-width: $max-width) {
        @content;
      }
    } @else if $orientation {
      @media (min-width: $min-width) and (max-width: $max-width) and (orientation: $orientation) {
        @content;
      }
    } @else {
      @content;
    }
  }
}
